export default [
  {
    id: 'CL103',
    name: 'Liquidación',
    is_numeric: true
  },
  {
    id: 'CL110',
    name: 'Factura de Exportación Electrónica',
    is_numeric: true
  },
  {
    id: 'CL111',
    name: 'Nota de Débito de Exportación Electrónica',
    is_numeric: true
  },
  {
    id: 'CL112',
    name: 'Nota de Crédito de Exportación Electrónica',
    is_numeric: true
  },
  {
    id: 'CL30',
    name: 'Factura',
    is_numeric: true
  },
  {
    id: 'CL32',
    name: 'Factura de venta bienes y servicios',
    is_numeric: true
  },
  {
    id: 'CL33',
    name: 'Factura Electrónica',
    is_numeric: true
  },
  {
    id: 'CL34',
    name: 'Factura No Afecta o Exenta Electrónica',
    is_numeric: true
  },
  {
    id: 'CL35',
    name: 'Boleta de papel fisico',
    is_numeric: true
  },
  {
    id: 'CL38',
    name: 'Boleta exenta',
    is_numeric: true
  },
  {
    id: 'CL39',
    name: 'Boleta Electrónica',
    is_numeric: true
  },
  {
    id: 'CL40',
    name: 'Liquidación Factura Papel',
    is_numeric: true
  },
  {
    id: 'CL41',
    name: 'Boleta Exenta Electrónica',
    is_numeric: true
  },
  {
    id: 'CL43',
    name: 'Liquidación-Factura Electrónica',
    is_numeric: true
  },
  {
    id: 'CL45',
    name: 'Factura de compra',
    is_numeric: true
  },
  {
    id: 'CL46',
    name: 'Factura de Compra Electrónica',
    is_numeric: true
  },
  {
    id: 'CL50',
    name: 'Guía de Despacho de Papel',
    is_numeric: true
  },
  {
    id: 'CL52',
    name: 'Guía de Despacho Electrónica',
    is_numeric: true
  },
  {
    id: 'CL55',
    name: 'Nota de débito',
    is_numeric: true
  },
  {
    id: 'CL56',
    name: 'Nota de Débito Electrónica',
    is_numeric: true
  },
  {
    id: 'CL60',
    name: 'Nota de crédito',
    is_numeric: true
  },
  {
    id: 'CL61',
    name: 'Nota de Crédito Electrónica',
    is_numeric: true
  },
  {
    id: 'CL801',
    name: 'Orden de compra',
    is_numeric: true
  },
  {
    id: 'CL802',
    name: 'Nota de pedido',
    is_numeric: false
  },
  {
    id: 'CL803',
    name: 'Contrato',
    is_numeric: false
  },
  {
    id: 'CL804',
    name: 'Resolución',
    is_numeric: true
  },
  {
    id: 'CL805',
    name: 'Proceso ChileCompra',
    is_numeric: true
  },
  {
    id: 'CL806',
    name: 'Ficha ChileCompra',
    is_numeric: true
  },
  {
    id: 'CL807',
    name: 'DUS',
    is_numeric: true
  },
  {
    id: 'CL808',
    name: 'B/L (Conocimiento de embarque)',
    is_numeric: true
  },
  {
    id: 'CL809',
    name: 'AWB (Air Will Bill)',
    is_numeric: true
  },
  {
    id: 'CL810',
    name: 'MIC/DTA',
    is_numeric: true
  },
  {
    id: 'CL811',
    name: 'Carta de Porte',
    is_numeric: true
  },
  {
    id: 'CL812',
    name: 'Resolución del SNA donde califica Servicios de Exportación',
    is_numeric: true
  },
  {
    id: 'CL813',
    name: 'Pasaporte',
    is_numeric: true
  },
  {
    id: 'CL814',
    name: 'Certificado de Depósito Bolsa Prod. Chile.',
    is_numeric: true
  },
  {
    id: 'CL815',
    name: 'Vale de Prenda Bolsa Prod. Chile',
    is_numeric: true
  },
  {
    id: 'CL820',
    name: 'Código Registro de Acuerdos con Plazo de Pago Excepcional',
    is_numeric: true
  },
  {
    id: 'CLCT',
    name: 'Cotización',
    is_numeric: false
  },
  {
    id: 'CLHES',
    name: 'HES',
    is_numeric: false
  },
  {
    id: 'CLOT',
    name: 'Orden de trabajo',
    is_numeric: false
  },
  {
    id: 'CLSEN',
    name: 'SEN',
    is_numeric: false
  },
  {
    id: 'CLOV',
    name: 'OV',
    is_numeric: false
  }
]
